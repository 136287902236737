import Invoice from '@/components/dialogs/invoice'
export default {
  components: {
    Invoice
  },
  methods: {
    onInfoClose() {
      this.$emit('componentInfo', { 
        status: true,
        component: 'invoice-list'
      })
    }
  }
}